<template>
  <vs-dialog scroll overflow-hidden not-close auto-width prevent-close v-model="this.active">
    <template #header>
      <h3>
        Ajustes de Privacidad
      </h3>
    </template>
    <div class="con-content">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>
              Acción
            </vs-th>
            <vs-th>
              Detalle
            </vs-th>
            <vs-th>
              Valor
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :ref="'row' + i" :key="i" v-for="(data, i) in mockData">
            <vs-td>
              {{ data.action }}
            </vs-td>
            <vs-td>
              {{ data.detalle }}
            </vs-td>
            <vs-td>
              {{ data.type }}
            </vs-td>
            <template #expand>
              <div class="con-content">
                <div class="buttonsGroup">
                  <vs-button
                    flat
                    icon
                    border
                    :active="data.type === 1"
                    @click="changeType(1, data.rule, $event)"
                  >
                    <i class="fas fa-globe-africa"></i>Publico
                  </vs-button>
                  <vs-button
                    flat
                    icon
                    border
                    :active="data.type === 2"
                    @click="changeType(2, data.rule, $event)"
                  >
                    <i class="fas fa-users"></i>Followers
                  </vs-button>
                  <vs-button
                    flat
                    icon
                    border
                    :active="data.type === 3"
                    @click="changeType(3, data.rule, $event)"
                  >
                    <i class="fas fa-user-lock"></i>Sólo yo
                  </vs-button>
                  <vs-button
                    flat
                    icon
                    border
                    :active="data.type === 4"
                    @click="changeType(4, data.rule, $event)"
                  >
                    <i class="fas fa-user-cog"></i>Personalizado
                  </vs-button>
                </div>
                <div v-if="data.type === 4" class="buttonsGroup">
                  <vs-select
                    v-if="users.length > 0"
                    style="max-width:100%; width:100%;"
                    label="Compartir sólo con ..."
                    filter
                    multiple
                    v-model="data.custom.alloweds"
                  >
                    <vs-option
                      v-for="(user, ind) in users"
                      :key="'user_' + ind"
                      :label="user.nick"
                      :value="user.nick"
                    >
                      {{ user.nick }}
                    </vs-option>
                  </vs-select>
                  <vs-select
                    v-if="users.length > 0"
                    style="max-width:100%; width:100%;"
                    label="No compatir con..."
                    filter
                    multiple
                    v-model="data.custom.blockeds"
                  >
                    <vs-option
                      v-for="(user, ind) in users"
                      :key="'user_' + ind"
                      :label="user.nick"
                      :value="user.nick"
                    >
                      {{ user.nick }}
                    </vs-option>
                  </vs-select>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <template #footer>
      <div class="con-footer">
        <vs-button block flat border success @click="setPrivacity" transparent>
          Ok
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import { imageMix, loadingMix } from "@/common/mixin.js";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "PrivacitySettings",
  mixins: [imageMix, loadingMix],
  data: () => {
    return {
      active: true,
      trigger: 0,
      mockData: [
        {
          rule: 1,
          action: "Publicar fotos en Eventos",
          detalle: "¿Quien puede subir fotos a tus eventos?",
          type: 1,
          custom: null
        },
        {
          rule: 2,
          action: "Comentar fotos ",
          detalle: "¿Quien puede comentar tus fotos?",
          type: 2,
          custom: { blockeds: "", alloweds: "" }
        },
        {
          rule: 3,
          action: "Comentar en Eventos",
          detalle: "¿Quien puede comentar tus eventos?",
          type: 3,
          custom: null
        }
      ],
      blockeds: [],
      alloweds: [],
      users: []
    };
  },
  computed: {},
  methods: {
    changeType(type, rule, oEvent) {
      this.mockData.forEach((oRule, ind) => {
        if (oRule.rule === rule) {
          oRule.type = type;
          if (type === 4) {
            oRule.custom = { blockeds: "", alloweds: "" };
          }
        }
      });
      this.$refs["row" + (rule - 1)][0].$el.click();
      this.$refs["row" + (rule - 1)][0].$el.click();
    },
    async setPrivacity() {
      this.openLoading();

      const body = {
        user_id: this.$store.state.currentUser.id,
        rules: JSON.stringify(this.mockData)
      };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .post("/privacity", body)
        .then(response => {
          this.active = false;
          this.$router.push("/");
        })
        .catch(error => {
          console.error(error);
        });
      this.closeLoading();
    },
    async getPrivacity() {
      this.openLoading();
      const body = { params: { user_id: this.$store.state.currentUser.id } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/privacity", body)
        .then(response => {
          this.mockData = JSON.parse(response.data.rules);
        })
        .catch(error => {
          console.error(error);
        });
      this.closeLoading();
    },
    async getProfiles() {
      this.active = true;
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/profile", {})
        .then(response => {
          const currentUser = this.$store.state.currentUser.nick;
          this.users = this.parseImages(response.data, _, true);
          let myInd = this.users.findIndex(a => {
            return a.nick === currentUser;
          });
          if (myInd > -1) {
            this.users.splice(myInd, 1);
          }
          this.filteredFollowers = this.users;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getProfiles();
    this.getPrivacity();
  }
};
</script>

<style scoped>
.buttonsGroup {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}
</style>
